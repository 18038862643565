/* @import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@700&family=Lato&family=Roboto+Mono:wght@200&family=Roboto:wght@400;700&display=swap');
 */
 @import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@700&family=Lato&family=Roboto+Mono:wght@200&family=Roboto:wght@700&display=swap');



body {
  margin: 0;
  /* font-family: 'Courier Prime', monospace; */
  /* font-family: 'Lato', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Roboto Mono', monospace; */


  background-color: "#FAF9F6";


}


.container {
  width: 15px;
  height: 15px;
  background:"#FAF9F6";
  border-radius: 30px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    background-color: "#FAF9F6";
}